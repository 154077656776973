import { createContext, useContext } from "react";

type Context = {
  state: Reimbursement.State;
  dispatch: React.Dispatch<Reimbursement.Action>;
  locked: boolean;
  deleteUrl?: string;
};

export const ReimbursementContext = createContext<Context>(null);

export default function useReimbursementContext() {
  return useContext(ReimbursementContext);
}
