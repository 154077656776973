import { TextField } from "../FormInput";
import Attachments from "./Attachments";
import Buttons from "./Buttons";
import Decision from "./Decision";
import Settlement from "./Settlement";
import useReimbursementContext from "./useReimbursementContext";

export default function Form() {
  const { state, dispatch, locked } = useReimbursementContext();
  const { reimbursement } = state;

  const handleChange = (attr: string) => (val: string) => {
    dispatch({ type: "update", payload: { [attr]: val } });
  };

  return (
    <div className="reimbursement-form">
      <h2>Utbetaling</h2>
      <div className="row">
        <TextField
          name="reimbursement[name]"
          label="Navn"
          onChange={handleChange("name")}
          disabled={locked}
          value={reimbursement.name}
          size={9}
        />
      </div>
      <Decision />
      <Settlement />
      <h3>Til utbetaling</h3>
      <div className="row">
        <TextField
          name="reimbursement[account_number]"
          label="Kontonummer"
          onChange={handleChange("account_number")}
          disabled={locked}
          validations={["accountNumber"]}
          value={reimbursement.account_number}
          size={3}
        />
        <TextField
          name="reimbursement[account_name]"
          label="Navn på kontoeier"
          onChange={handleChange("account_name")}
          disabled={locked}
          value={reimbursement.account_name}
          size={6}
        />
      </div>
      <Attachments />
      <Buttons />
    </div>
  );
}
