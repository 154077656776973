import useReimbursementForm from "./ReimbursementForm/useReimbursementForm";
import { ReimbursementContext } from "./ReimbursementForm/useReimbursementContext";
import Form from "./ReimbursementForm/Form";

interface Props {
  reimbursement: Reimbursement.Resource;
  locked: boolean;
  deleteUrl?: string;
}

export default function ReimbursementForm(props: Props) {
  const { deleteUrl, locked } = props;
  const [state, dispatch] = useReimbursementForm(props.reimbursement);

  return (
    <ReimbursementContext.Provider
      value={{
        state: state,
        dispatch: dispatch,
        locked: locked,
        deleteUrl: deleteUrl
      }}>
      <Form />
    </ReimbursementContext.Provider>
  );
}
