import { formatCurrency, formatDecimal } from "../../lib/numbers";
import { Fragment } from "react";
import { TextField, TextArea } from "../FormInput";
import calculateSettlement from "./calculateSettlement";
import useReimbursementContext from "./useReimbursementContext";

export default function Settlement() {
  const { state, dispatch, locked } = useReimbursementContext();
  const { reimbursement } = state;

  const settlement = calculateSettlement(state);

  const handleChange = (attr: string) => (val: string) => {
    dispatch({ type: "update", payload: { [attr]: val } });
  };

  return (
    <Fragment>
      <h3>Avregning</h3>
      <div className="row">
        <TextField
          name="reimbursement[sum]"
          label="Rapportert kostnad"
          disabled={true}
          value={formatCurrency(reimbursement.reported_cost)}
          size={3}
        />
        <TextField
          name="reimbursement[approved_cost]"
          label="Godkjent kostnad"
          onChange={handleChange("approved_cost")}
          disabled={locked}
          value={reimbursement.approved_cost}
          validations={["decimal"]}
          size={3}
        />
      </div>
      <div className="row">
        <TextField
          name="reimbursement[grant]"
          onChange={() => {}}
          label="Tilskudd"
          disabled={true}
          value={formatCurrency(settlement.grant.toFixed())}
          size={3}
        />
        <TextField
          name="reimbursement[adjusted_grant]"
          label="Korrigert tilskudd"
          onChange={handleChange("adjusted_grant")}
          disabled={locked}
          value={reimbursement.adjusted_grant}
          validations={["decimal"]}
          size={3}
        />
      </div>
      <div className="row">
        <TextField
          name="reimbursement[deductible]"
          onChange={() => {}}
          label="Egenandel"
          disabled={true}
          value={formatCurrency(settlement.deductible.toFixed())}
          size={3}
        />
        <TextField
          name="reimbursement[adjusted_deductible_sum]"
          label="Korrigert egenandel"
          onChange={handleChange("adjusted_deductible_sum")}
          disabled={locked}
          value={reimbursement.adjusted_deductible_sum}
          validations={["decimal"]}
          size={3}
        />
        <TextField
          name="reimbursement[deductible_percent]"
          onChange={() => {}}
          label="Egenandel %"
          disabled={true}
          value={formatDecimal(settlement.deductiblePercent.toFixed())}
          size={3}
        />
      </div>
      <div className="row">
        <TextField
          name="reimbursement[advance_paid]"
          label="Utbetalt forskudd"
          onChange={handleChange("advance_paid")}
          disabled={locked}
          value={reimbursement.advance_paid}
          validations={["decimal"]}
          size={3}
        />
      </div>
      <div className="row">
        <TextField
          name="reimbursement[sum]"
          onChange={() => {}}
          label="Sum til utbetaling"
          disabled={true}
          value={formatCurrency(settlement.sum.toFixed())}
          size={3}
        />
        <TextField
          name="reimbursement[adjusted_sum]"
          label="Korrigert sum til utbetaling"
          onChange={handleChange("adjusted_sum")}
          disabled={locked}
          value={reimbursement.adjusted_sum}
          validations={["decimal"]}
          size={3}
        />
      </div>
      <div className="row">
        <TextField
          name="reimbursement[remaining]"
          onChange={() => {}}
          label="Gjenstående"
          disabled={true}
          value={formatCurrency(settlement.remaining.toFixed())}
          size={3}
        />
      </div>
      <div className="row">
        <TextArea
          name="reimbursement[comments]"
          label="Kommenter evt. endringer"
          onChange={handleChange("comments")}
          disabled={locked}
          value={reimbursement.comments}
          size={9}
        />
      </div>
    </Fragment>
  );
}
