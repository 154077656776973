import { submitDeleteForm } from "../../lib/request";
import useReimbursementContext from "./useReimbursementContext";
import { valid, validDraft } from "./validations";

export default function Buttons() {
  const { state, locked, deleteUrl } = useReimbursementContext();
  const { reimbursement } = state;

  const handleDelete = (evt: React.MouseEvent) => {
    evt.preventDefault();
    if (confirm("Er du sikker på at du vil slette denne utbetalingen?")) {
      submitDeleteForm(deleteUrl);
    }
  };

  return (
    <div className="buttons tight">
      {!locked && (
        <button
          className="secondary"
          disabled={!validDraft(state.reimbursement)}
          type="submit"
          name="submit_action"
          value="save">
          <i className="material-icons">save</i>
          Lagre utkast
        </button>
      )}
      {deleteUrl && (
        <button onClick={handleDelete}>
          <i className="material-icons">delete</i>
          Slett utkast
        </button>
      )}
      {!locked && (
        <button
          disabled={!valid(reimbursement)}
          type="submit"
          name="submit_action"
          value="send">
          <i className="material-icons">send</i>
          Opprett utbetaling
        </button>
      )}
    </div>
  );
}
