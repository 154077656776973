import { formatCurrency, formatDecimal } from "../../lib/numbers";
import { Fragment } from "react";
import { TextField } from "../FormInput";
import useReimbursementContext from "./useReimbursementContext";

export default function Decision() {
  const { state } = useReimbursementContext();
  const { decision } = state;

  return (
    <Fragment>
      <h3>Vedtak</h3>
      <div className="row">
        <TextField
          name="decision[sum]"
          label="Anbefalt totalkostnad"
          disabled={true}
          value={formatCurrency(decision.sum)}
          size={3}
        />
        <TextField
          name="decision[grant]"
          label="Tilskudd"
          disabled={true}
          value={formatCurrency(decision.grant)}
          size={3}
        />
        <TextField
          name="decision[deductible]"
          label="Egenandel %"
          disabled={true}
          value={formatDecimal(decision.deductible)}
          size={3}
        />
      </div>
    </Fragment>
  );
}
