import { Fragment } from "react";
import { FileUploader } from "../FormInput";
import useReimbursementContext from "./useReimbursementContext";

export default function Attachments() {
  const { state, dispatch } = useReimbursementContext();
  const { attachments, reimbursement } = state;

  const handleChange = (as: Attachment[]) => {
    dispatch({ type: "setAttachments", payload: as });
  };

  return (
    <Fragment>
      <h3>Vedlegg</h3>
      <div className="row">
        <FileUploader
          name="reimbursement[attachments]"
          files={attachments}
          fileTypes={{ extensions: ["pdf"], mimeTypes: ["application/pdf"] }}
          caseId={reimbursement.case_id}
          onChange={handleChange}
          size={8}
        />
      </div>
    </Fragment>
  );
}
